<template>
  <div class="material-container relative fs14">
    <Skeleton v-show="pageLoading" />
    <!-- <scroll-view
      v-show="!pageLoading"
      class="material-container"
      scroll-y
      :scroll-top="scrollTop"
      @scroll="onScroll"
    > -->
    <div
      v-show="!pageLoading"
      class="material-container overflow-y-scroll"
      ref="materialContainer"
      @scroll="onScroll"
    >
      <div
        class="material-list pbs relative"
        :class="[isCollect || searchText ? 'plr12' : 'pl8']"
      >
        <div v-if="guideText" class="material-guide flex mb8 mr8 bdr4 pl8 pr8">
          <img
            class="hint-icon flex-s0 mt8 mr8"
            src="@/assets/img/material-library/hint.svg"
          />
          <div class="mt9 mb8 cl69 fs12 wb">
            {{ guideText }}
          </div>
        </div>
        <div v-if="dataSource.length">
          <div
            v-for="(item, index) in dataSource"
            :key="index"
            class="material-item"
          >
            <MaterialItem
              :dataSource="item"
              :searchText="searchText"
              :isCollect="isCollect"
              :map_idx_id="map_idx_id"
              @showModal="(data) => showModal(item, data)"
            />
          </div>
        </div>
        <div
          v-else
          class="empty-data flex flex-direction-column flex-justify-center flex-align-center fs12 cl99 tc"
        >
          <img
            src="@/assets/img/customer-portrait/no_user_data.svg"
            class="img block"
          />
          暂无素材
        </div>
      </div>
    </div>
    <!-- </scroll-view> -->
    <!-- <uni-popup
      ref="shareCover"
      type="share"
      safe-area
      background-color="#fff"
      style="position: fixed; z-index: 9999"
    >
      <div class="share-pop-sendItem pl70 pr70 pt50" @click.stop="">
        <div class="flex flex-justify-between pb50">
          <div class="share-item" @click="onHandle('customer')">
            <img
              class="share-item-icon block"
              :src="`@/assets/knowledge/customer.svg`"
              mode="widthFix"
            />
            <div class="fs24 lh34 cl17 pt10">群发至客户</div>
          </div>
          <div class="share-item" @click="onHandle('group')">
            <img
              class="share-item-icon block"
              :src="`@/assets/knowledge/customer_group.svg`"
              mode="widthFix"
            />
            <div class="fs24 lh34 cl17 pt10">群发至客户群</div>
          </div>
          <div class="share-item" @click="onHandle('circleFriends')">
            <img
              class="share-item-icon block"
              :src="`@/assets/knowledge/Moments.svg`"
              mode="widthFix"
            />
            <div class="fs24 lh34 cl17 pt10">发至朋友圈</div>
          </div>
        </div>
        <div class="cancel-btn cl17 tc fs28" @click="closePopup">取消</div>
      </div>
    </uni-popup> -->
    <ModalView
      v-model="modalVisible"
      cancelText="直接发送"
      confirmText="查看"
      maskFun
      @confirm="onNewest"
      @cancel="showPopup"
    >
      <div class="cl16 cl29 fw500 pt30 pb50 tc">
        {{ selectedData.tips || '' }}
      </div>
    </ModalView>
  </div>
</template>

<script>
import MaterialItem from './materialItem.vue'
import ModalView from '@/components/modalView/index.vue'
import {
  getSaleScriptList,
  addSendContent
} from '@/service/material.service.js'
import { sendMonitor, setBackSend } from '@/service/quickSend.service.js'
import Skeleton from './skeleton.vue'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { formatType, formatSendParams } from '@/utils/sendMessage.js'
import { Toast } from 'vant'
import bus from '@/utils/bus'
import { encodeParams } from '@/utils/router'

export default {
  components: {
    MaterialItem,
    Skeleton,
    ModalView
  },
  props: {
    qw_external_userid: {
      // 外部联系人userid (聊天工具栏才有)
      type: String,
      default: () => ''
    },
    qw_chat_id: {
      // 当前客户群的群ID (聊天工具栏才有)
      type: String,
      default: () => ''
    },
    map_idx_id: {
      //泛客户id
      type: [Number, String],
      default: ''
    },
    selectedFirstLevel: {
      // 选中的一级分类数据
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      guideText: '', // 素材引导文案
      searchText: '', // 搜索框内容
      searchData: {}, // 搜索条件
      dataSource: [], // 素材列表
      scrollTop: 0, // scroll滚动top
      // oldScrollTop: 0, // 旧scroll滚动top
      // isReady: false, // 是否初次渲染完成
      modalVisible: false, // 提示更新弹窗显示
      selectedMaterialData: {}, // 选中的素材数据
      selectedData: {}, // 选中的内容包数据
      pageLoading: true // 骨架屏加载
    }
  },
  mounted() {
    const _this = this
    bus.on('getMaterialList', function (data = {}) {
      const { guideText = '', key, ...other } = data
      if (key !== 'onShow') _this.guideText = guideText
      if (JSON.stringify(other) !== '{}') _this.searchData = other
      if (key === 'first') _this.pageLoading = true
      _this.getDataSource(key)
    })
  },
  // onShow() {
  //   if (this.isReady) {
  //     this.getDataSource('onShow')
  //   }
  // },
  activated() {
    this.getDataSource('onShow')
    this.$nextTick(() => {
      this.$refs.materialContainer.scrollTop = this.scrollTop
    })
  },
  unmounted() {
    bus.off('getMaterialList')
  },
  computed: {
    isCollect() {
      return this.selectedFirstLevel.id === 'collect'
    }
  },
  methods: {
    // 监听滚动事件
    onScroll(event) {
      this.scrollTop = event.target.scrollTop
    },
    // 获取素材列表 key 'first': 首次加载, 'onShow': 激活, 'load': 加载更多
    async getDataSource(key) {
      const _this = this
      const { qw_external_userid, searchData, map_idx_id } = this
      const { group_id = '', keyword = '', first_group_id = '' } = searchData
      this.searchText = keyword
      const body = { group_id, keyword, map_idx_id }
      if (qw_external_userid) {
        ;(body.type = '1'), (body.qw_user_chat_id = qw_external_userid)
      }
      if (first_group_id === 'collect') body.is_collect = '1'
      if (keyword) {
        body.first_group_id =
          first_group_id !== 'collect' && first_group_id !== 'all'
            ? first_group_id
            : ''
      }

      const { data } = await getSaleScriptList(body)
      if (this.pageLoading) {
        setTimeout(() => {
          this.pageLoading = false

          if (key === 'first') {
            this.$nextTick(() => {
              if (_this.$refs.materialContainer) {
                _this.scrollTop = 0
                _this.$refs.materialContainer.scrollTop = 0
              }
            })
            // this.scrollTop = this.oldScrollTop
            // this.$nextTick(() => {
            //   _this.scrollTop = 0
            // })
          }
        }, 300)
      }

      this.dataSource = data
    },
    // 页面滑动事件
    // onScroll: debounce(function ({ detail }) {
    //   this.oldScrollTop = detail.scrollTop
    // }, 600),
    showPopup() {
      const { qw_external_userid, qw_chat_id } = this
      if (qw_external_userid || qw_chat_id) {
        this.onSend()
      } else {
        // this.$refs.shareCover.show()
      }
    },
    closePopup() {
      // this.$refs.shareCover.close()
    },
    // 控制弹窗显示隐藏
    showModal(materialData = {}, data = {}) {
      const { status_tips } = data
      this.selectedMaterialData = materialData
      this.selectedData = data
      if (String(status_tips) === '2') {
        this.modalVisible = true
      } else {
        this.showPopup()
      }
    },
    // async onHandle(key) {
    //   const { content_arr, content_key, status_tips } = this.selectedData
    //   const { id } = this.selectedMaterialData
    //   const { qw_userid } = localStorage.getItem('user_info')
    //   const content_member_id =
    //     String(status_tips) === '1' || String(status_tips) === '2'
    //       ? qw_userid
    //       : '0'
    //   setTimeout(() => this.closePopup(), 300)
    //   if (key === 'group') {
    //     navigateTo('/pagesTool/sendGuide/index', {
    //       pageType: key,
    //       materialList: content_arr
    //     })
    //   } else {
    //     navigateTo('/pagesTool/screenCustomer/index', {
    //       pageType: key,
    //       sale_script_id: id,
    //       content_key,
    //       content_member_id,
    //       materialList: content_arr
    //     })
    //   }
    // },
    // 查看更新
    onNewest() {
      const { content_key, member_id, status_tips, tips } = this.selectedData
      const { id, title } = this.selectedMaterialData
      this.$router.push({
        name: 'material-edit',
        query: encodeParams({
          sale_script_id: id,
          content_key,
          member_id,
          status_tips,
          title,
          tips,
          isNewest: true
        })
      })
      // navigateTo('/pages/material/materialEdit/index', {
      //   sale_script_id: id,
      //   content_key,
      //   member_id,
      //   status_tips,
      //   title,
      //   tips,
      //   isNewest: true
      // })
    },
    // 聊天工具栏发送内容包
    async onSend() {
      try {
        const {
          selectedData,
          selectedMaterialData,
          qw_external_userid,
          qw_chat_id
        } = this
        const { content_arr } = selectedData
        let status = false
        Toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        })

        const share_data_id = await this.beforeSend()
        for (let i = 0; i < content_arr.length; i++) {
          let type = formatType(content_arr[i].type)
          let params = await formatSendParams(
            type,
            {
              ...content_arr[i],
              sale_script_id: selectedMaterialData.id,
              customer_id: qw_external_userid,
              chart_id: qw_chat_id,
              source: 'sale_script'
            },
            share_data_id
          )
          console.log('发送参数:::', type, params)
          const { sendStatus } = await sendChatMessageAllType(
            type,
            params,
            false
          )
          if (sendStatus) status = true
        }
        Toast.clear()
        if (status) {
          Toast.success({ message: '发送成功!' })
          this.recordDynamics()
          this.recordSend()
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 发送前获取埋点id
    async beforeSend() {
      const { selectedData, selectedMaterialData, qw_external_userid } = this

      if (!qw_external_userid) return ''
      const body = {
        payable_id: selectedMaterialData.id,
        type: 'sale_script',
        user_id: qw_external_userid,
        content_key: selectedData.content_key
      }

      let { data } = await sendMonitor(body)
      return data?.share_data_id || ''
    },
    // 记录发送动态
    async recordDynamics() {
      const {
        selectedData,
        selectedMaterialData,
        qw_external_userid,
        qw_chat_id
      } = this

      const body = {
        sale_script_id: selectedMaterialData.id,
        field_value: qw_external_userid || qw_chat_id || '',
        event_key: '',
        field: '',
        content_key: selectedData.content_key
      }
      if (qw_external_userid) {
        body.event_key = 'customer_receive_sale_script'
        body.field = 'external_contact'
      } else if (qw_chat_id) {
        body.event_key = 'customer_touch_sale_script_group'
        body.field = 'chat_id'
      }

      await setBackSend(body)
    },
    // 好友聊天工具栏发送内容包
    async recordSend() {
      const {
        qw_external_userid,
        qw_chat_id,
        selectedData,
        selectedMaterialData
      } = this
      const { content_arr, content_key, status_tips } = selectedData
      const { id } = selectedMaterialData
      const userInfo = JSON.parse(localStorage.getItem('vuex')) || {}
      const content_member_id =
        String(status_tips) === '1' || String(status_tips) === '2'
          ? userInfo.user.info.qw_userid
          : '0'
      const body = {
        sale_script_id: id,
        content_arr,
        content_key,
        content_member_id,
        type: qw_external_userid ? '1' : '2',
        qw_user_chat_id: qw_external_userid || qw_chat_id
      }
      const { code } = await addSendContent(body)
      if (code == 200) {
        this.getDataSource('onShow')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mt9 {
  margin-top: 9px;
}
.material-container {
  width: 100%;
  height: 100%;

  .pbs {
    padding-bottom: calc(8px + env(safe-area-inset-bottom) / 2) !important;
  }
  .material-list {
    min-height: 100%;
    padding-top: 6px;
  }
  .material-item {
    margin-bottom: 8px;
  }
  .material-item:last-child {
    margin-bottom: 0 !important;
  }
  .material-guide {
    line-height: 22px;
    background: linear-gradient(180deg, #fff5f2 0%, #ffffff 100%);
    border: 1px solid #ffffff;
    box-shadow: 0 16px 20px -12px rgba(95, 129, 133, 0.08);

    .hint-icon {
      width: 24px;
      height: 24px;
    }
  }
  .empty-data {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;

    .img {
      width: 200px;
      height: 132px;
    }
  }
  .share-pop-sendItem {
    border-radius: 10px;

    .share-item-icon {
      width: 42px;
      height: 42px;
      margin: 0 auto;
    }

    .cancel-btn {
      line-height: 40px;
    }
  }
}
</style>
