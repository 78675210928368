<template>
  <div class="container flex flex-direction-column relative fs14">
    <div class="container-header bgth flex-s0">
      <div
        class="search-input-box flex flex-align-center bgf bdr2 pr8 pl12"
        @click="onSearch"
      >
        <span
          class="search-input flex-1 wb overflow-h"
          :class="[searchText ? 'cl29' : 'cl9c']"
        >
          {{ searchText || '搜索' }}
        </span>
        <div class="icon-frame flex-s0 flex flex-align-center">
          <img class="icon" src="@/assets/img/common/search.svg" />
        </div>
      </div>
      <div ref="firstLevelTabs" id="first-level-tabs" class="first-level-tabs">
        <div
          v-for="(item, index) in dataSource"
          :key="index"
          class="tab-item clff"
          :class="{ 'tab-item-active': selectedFirstLevel.id === item.id }"
          @click="onSelect(item)"
        >
          <div class="tab-item-value relative flex flex-align-center">
            <img
              v-if="item.id === 'collect'"
              class="icon mr10"
              src="@/assets/img/material-library/collect-white.svg"
            />
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 flex bgf5 overflow-h">
      <ClassifyView
        v-if="
          selectedFirstLevel.id &&
          selectedFirstLevel.id !== 'collect' &&
          !searchText
        "
        class="classify-container flex-s0"
        :selectedFirstLevel="selectedFirstLevel"
      />
      <MaterialView
        class="material-container flex-1"
        :qw_external_userid="qw_external_userid"
        :qw_chat_id="qw_chat_id"
        :map_idx_id="map_idx_id"
        :selectedFirstLevel="selectedFirstLevel"
      />
    </div>
  </div>
</template>

<script>
import ClassifyView from './components/classifyView.vue'
import MaterialView from './components/materialView.vue'
// import { navigateTo } from '@/utils/router.js'
import {
  getContext,
  getCurExternalContact,
  getCurExternalChat
} from '@/utils/nwechat.js'
import {
  getSaleScriptGroupList,
  getSaleScriptList
} from '@/service/material.service.js'
import bus from '@/utils/bus'
import { getMobileByExternalUserid } from '@/service/customer.service.js'

export default {
  components: { ClassifyView, MaterialView },
  data() {
    return {
      dataSource: [
        // 一级分类
        { key: 'collect', value: '收藏' }
      ],
      selectedFirstLevel: {}, // 选中的一级分类数据
      searchText: '', // 搜索框内容
      contentScrollW: 0, // 导航区宽度
      scrollLeft: 0, // 横向滚动条位置
      qw_external_userid: '', // 外部联系人userid (聊天工具栏才有)
      qw_chat_id: '', // 当前客户群的群ID (聊天工具栏才有)
      map_idx_id: '' // 泛客户id
    }
  },
  async mounted() {
    await this.getEntryType()
    await this.getDataSource()
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.firstLevelTabs.scrollLeft = this.scrollLeft
    })
  },
  methods: {
    // 选择一级分类
    onSelect(data = {}) {
      const { searchText, contentScrollW } = this
      this.selectedFirstLevel = data
      const _this = this
      const scrollLeft = data.left - contentScrollW / 2 + data.width / 2
      this.$nextTick(() => {
        if (_this.$refs.firstLevelTabs) {
          _this.scrollLeft = scrollLeft
          _this.$refs.firstLevelTabs.scrollLeft = scrollLeft
        }
      })

      if (searchText || data.id === 'collect') {
        bus.emit('getMaterialList', {
          key: 'first',
          keyword: searchText,
          first_group_id: data.id
        })
      }
    },
    // 跳转查询页面
    onSearch() {
      const { searchText } = this
      const _this = this

      bus.on('onSearch', function (text) {
        _this.searchText = text
        _this.getDataSource()
        // uni.$off('onSearch')
      })
      this.$router.push({ name: 'material-search', query: { searchText } })
    },
    // 获取分类数据
    async getDataSource() {
      const _this = this
      const { searchText } = this
      const { data = [] } = await getSaleScriptGroupList({
        keyword: searchText || '',
        status: '1'
      })
      let newData = []
      const collectCount = await this.getCollectCount()
      if (collectCount || !searchText) {
        newData.unshift({ id: 'collect', name: '收藏' })
      }
      if (searchText) {
        newData.unshift({ id: 'all', name: '全部' })
        let count = 0
        // for (let i = 0; i < data.length; i++) {
        //   count = count + data[i].count
        //   data[i].name = `${data[i].name}${
        //     data[i].count ? ' (' + data[i].count + ')' : ''
        //   }`
        // }
        newData = [
          ...newData,
          ...data.map((item) => {
            count = count + item.count
            return {
              ...item,
              name: `${item.name}${item.count ? ' (' + item.count + ')' : ''}`
            }
          })
        ]
        newData[0].name = `${newData[0].name}${count ? ' (' + count + ')' : ''}`
        if (collectCount) {
          newData[1].name = `${newData[1].name}${
            collectCount ? ' (' + collectCount + ')' : ''
          }`
        }
      } else {
        newData = [...newData, ...data]
      }
      this.dataSource = [...newData]
      this.selectedFirstLevel =
        newData[!searchText && !collectCount && data.length ? 1 : 0]
      this.onSelect(this.selectedFirstLevel)
      this.$nextTick(() => {
        _this.getScrollWidth()
      })
    },
    // 搜索时获取'收藏'的length
    async getCollectCount() {
      const { searchText } = this
      const body = { keyword: searchText, is_collect: '1' }
      const { data = [] } = await getSaleScriptList(body)
      return data.length || 0
    },
    // 获取标题区域宽度，和每个子元素节点的宽度
    getScrollWidth() {
      const firstLevelTabs = document.getElementById('first-level-tabs')
      this.contentScrollW = firstLevelTabs.offsetWidth
      const chchildNodes = firstLevelTabs.querySelectorAll('.tab-item')
      if (chchildNodes && chchildNodes.length) {
        for (let i = 0; i < chchildNodes.length; i++) {
          //  scroll-view 子元素组件距离左边栏的距离
          this.dataSource[i].left = chchildNodes[i].offsetLeft
          //  scroll-view 子元素组件宽度
          this.dataSource[i].width = chchildNodes[i].offsetWidth
        }
      }
    },
    // 获取页面入口类型
    async getEntryType() {
      // this.qw_external_userid = 'wm1DICCQAAq9tj0yzhQu1uDVYbKoTnLg'
      // this.getMemberMappingInfo()
      const entry = await getContext()
      if (entry === 'single_chat_tools') {
        this.qw_external_userid = await getCurExternalContact()
        await this.getMemberMappingInfo()
      } else if (entry === 'group_chat_tools') {
        this.qw_chat_id = await getCurExternalChat()
      }
    },
    // 根据外部联系人id获取业务id、泛客户id
    async getMemberMappingInfo() {
      const { qw_external_userid } = this
      const { data, code } = await getMobileByExternalUserid({
        qw_external_userid
      })
      if (code == 200) {
        this.map_idx_id = data.map_idx_id
      }
    }
  }
}
</script>

<style>
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
</style>
<style lang="less" scoped>
.icon {
  width: 16px;
  height: 16px;
}
.pl12 {
  padding-left: 12px;
}
.container {
  height: 100%;

  .container-header {
    padding-top: 6px;

    .search-input-box {
      height: 32px;
      margin: 0 15px 6px 15px;
      border: 1px solid #f2f3f5;

      .search-input {
        height: 20px;
        line-height: 20px;
      }
      .icon-frame {
        height: 100%;
        padding: 0 4px;
      }
    }
    .first-level-tabs {
      overflow-x: scroll;
      white-space: nowrap;

      .tab-item {
        padding: 13px 16px;
        opacity: 0.8;
        display: inline-block;
        vertical-align: bottom;

        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
      .tab-item-active {
        opacity: 1;

        .tab-item-value {
          &::after {
            position: absolute;
            left: 0;
            bottom: -10px;
            content: '';
            width: 100%;
            height: 3px;
            background: #ffffff;
            box-shadow: 0px 2px 10px 0px rgba(2, 157, 157, 0.1);
            border-radius: 5px 5px 0 0;
          }
        }
      }
    }
  }
  .classify-container {
    width: 25%;
  }
  .search-history {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
  }
}
</style>
