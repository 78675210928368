<template name="skeleton">
  <div class="sk-container">
    <div class="container data-v-33d12630">
      <div class="r data-v-33d12630">
        <div
          class="container component--container flex component--flex flex-direction-column component--flex-direction-column relative component--relative data-v-60a9e493 component--data-v-60a9e493"
        >
          <div
            class="flex-1 component--flex-1 flex component--flex bgf5 component--bgf5 overflow-h component--overflow-h data-v-60a9e493 component--data-v-60a9e493"
          >
            <div
              class="material-container component--material-container flex-1 component--flex-1 data-v-60a9e493 component--data-v-60a9e493"
            >
              <div
                scroll-y="true"
                class="material-container materialView--material-container data-v-099eb9db materialView--data-v-099eb9db"
                scroll-top="0"
              >
                <div
                  class="material-list materialView--material-list pt8 materialView--pt8 pbs materialView--pbs relative materialView--relative data-v-099eb9db materialView--data-v-099eb9db plr12 materialView--plr12"
                >
                  <div class="data-v-099eb9db materialView--data-v-099eb9db">
                    <div
                      class="material-item materialView--material-item data-v-099eb9db materialView--data-v-099eb9db"
                    >
                      <div
                        class="data-v-099eb9db materialView--data-v-099eb9db"
                      >
                        <div
                          class="material-item materialItem--material-item bgf materialItem--bgf bdr2 materialItem--bdr2 data-v-08871349 materialItem--data-v-08871349"
                        >
                          <div
                            class="pt8 materialItem--pt8 pl8 pr8 materialItem--pl8 fw500 materialItem--fw500 cl29 materialItem--cl29 bdb materialItem--bdb pb8 materialItem--pb8 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-801 sk-text"
                          >
                            今日分享_素材一
                          </div>
                          <div
                            class="pt8 materialItem--pt8 pl8 materialItem--pl8 pr8 materialItem--pr8 data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="cl29 materialItem--cl29 wb materialItem--wb mb8 materialItem--mb8 line-3 materialItem--line-3 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-155 sk-text"
                            >
                              我进行了编辑
                            </div>
                            <div
                              class="flex materialItem--flex flex-wrap materialItem--flex-wrap flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-video materialItem--media-video data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  object-fit="contain"
                                  src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/ec99d678fd315bdf049d5a8f3a3dbbd6.mp4"
                                  unit-id="true"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                            </div>
                            <div
                              class="flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <span
                                class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                              >
                                近30天内2人发过
                              </span>
                            </div>
                            <div
                              class="media-handle materialItem--media-handle flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center pb4 materialItem--pb4 data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="flex-1 materialItem--flex-1 flex materialItem--flex flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    已收藏
                                  </span>
                                </div>
                                <div
                                  class="part materialItem--part data-v-08871349 materialItem--data-v-08871349"
                                ></div>
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    编辑
                                  </span>
                                </div>
                              </div>
                              <div
                                class="handle-box materialItem--handle-box flex-s0 materialItem--flex-s0 flex materialItem--flex flex-align-center materialItem--flex-align-center mr8 materialItem--mr8 fs12 materialItem--fs12 cl9c materialItem--cl9c fw500 materialItem--fw500 data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                              >
                                <div
                                  class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <span
                                  class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                >
                                  发送
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="r materialItem--r data-v-08871349 materialItem--data-v-08871349"
                            style="position: fixed; z-index: 99"
                          ></div>
                          <div
                            class="data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="r modalView-index--r data-v-1965c6ec modalView-index--data-v-1965c6ec"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="material-item materialView--material-item data-v-099eb9db materialView--data-v-099eb9db"
                    >
                      <div
                        class="data-v-099eb9db materialView--data-v-099eb9db"
                      >
                        <div
                          class="material-item materialItem--material-item bgf materialItem--bgf bdr2 materialItem--bdr2 data-v-08871349 materialItem--data-v-08871349"
                        >
                          <div
                            class="pt8 materialItem--pt8 pl8 pr8 materialItem--pl8 fw500 materialItem--fw500 cl29 materialItem--cl29 bdb materialItem--bdb pb8 materialItem--pb8 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-801 sk-text"
                          >
                            今日分享_素材一
                          </div>
                          <div
                            class="pt8 materialItem--pt8 pl8 materialItem--pl8 pr8 materialItem--pr8 data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="cl29 materialItem--cl29 wb materialItem--wb mb8 materialItem--mb8 line-3 materialItem--line-3 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-155 sk-text"
                            >
                              我进行了编辑
                            </div>
                            <div
                              class="flex materialItem--flex flex-wrap materialItem--flex-wrap flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-video materialItem--media-video data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  object-fit="contain"
                                  src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/ec99d678fd315bdf049d5a8f3a3dbbd6.mp4"
                                  unit-id="true"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                            </div>
                            <div
                              class="flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <span
                                class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                              >
                                近30天内2人发过
                              </span>
                            </div>
                            <div
                              class="media-handle materialItem--media-handle flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center pb4 materialItem--pb4 data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="flex-1 materialItem--flex-1 flex materialItem--flex flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    已收藏
                                  </span>
                                </div>
                                <div
                                  class="part materialItem--part data-v-08871349 materialItem--data-v-08871349"
                                ></div>
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    编辑
                                  </span>
                                </div>
                              </div>
                              <div
                                class="handle-box materialItem--handle-box flex-s0 materialItem--flex-s0 flex materialItem--flex flex-align-center materialItem--flex-align-center mr8 materialItem--mr8 fs12 materialItem--fs12 cl9c materialItem--cl9c fw500 materialItem--fw500 data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                              >
                                <div
                                  class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <span
                                  class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                >
                                  发送
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="r materialItem--r data-v-08871349 materialItem--data-v-08871349"
                            style="position: fixed; z-index: 99"
                          ></div>
                          <div
                            class="data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="r modalView-index--r data-v-1965c6ec modalView-index--data-v-1965c6ec"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="material-item materialView--material-item data-v-099eb9db materialView--data-v-099eb9db"
                    >
                      <div
                        class="data-v-099eb9db materialView--data-v-099eb9db"
                      >
                        <div
                          class="material-item materialItem--material-item bgf materialItem--bgf bdr2 materialItem--bdr2 data-v-08871349 materialItem--data-v-08871349"
                        >
                          <div
                            class="pt8 materialItem--pt8 pl8 pr8 materialItem--pl8 fw500 materialItem--fw500 cl29 materialItem--cl29 bdb materialItem--bdb pb8 materialItem--pb8 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-801 sk-text"
                          >
                            今日分享_素材一
                          </div>
                          <div
                            class="pt8 materialItem--pt8 pl8 materialItem--pl8 pr8 materialItem--pr8 data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="cl29 materialItem--cl29 wb materialItem--wb mb8 materialItem--mb8 line-3 materialItem--line-3 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-155 sk-text"
                            >
                              我进行了编辑
                            </div>
                            <div
                              class="flex materialItem--flex flex-wrap materialItem--flex-wrap flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-video materialItem--media-video data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  object-fit="contain"
                                  src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/ec99d678fd315bdf049d5a8f3a3dbbd6.mp4"
                                  unit-id="true"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                            </div>
                            <div
                              class="flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <span
                                class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                              >
                                近30天内2人发过
                              </span>
                            </div>
                            <div
                              class="media-handle materialItem--media-handle flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center pb4 materialItem--pb4 data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="flex-1 materialItem--flex-1 flex materialItem--flex flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    已收藏
                                  </span>
                                </div>
                                <div
                                  class="part materialItem--part data-v-08871349 materialItem--data-v-08871349"
                                ></div>
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    编辑
                                  </span>
                                </div>
                              </div>
                              <div
                                class="handle-box materialItem--handle-box flex-s0 materialItem--flex-s0 flex materialItem--flex flex-align-center materialItem--flex-align-center mr8 materialItem--mr8 fs12 materialItem--fs12 cl9c materialItem--cl9c fw500 materialItem--fw500 data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                              >
                                <div
                                  class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <span
                                  class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                >
                                  发送
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="r materialItem--r data-v-08871349 materialItem--data-v-08871349"
                            style="position: fixed; z-index: 99"
                          ></div>
                          <div
                            class="data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="r modalView-index--r data-v-1965c6ec modalView-index--data-v-1965c6ec"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="material-item materialView--material-item data-v-099eb9db materialView--data-v-099eb9db"
                    >
                      <div
                        class="data-v-099eb9db materialView--data-v-099eb9db"
                      >
                        <div
                          class="material-item materialItem--material-item bgf materialItem--bgf bdr2 materialItem--bdr2 data-v-08871349 materialItem--data-v-08871349"
                        >
                          <div
                            class="pt8 materialItem--pt8 pl8 pr8 materialItem--pl8 fw500 materialItem--fw500 cl29 materialItem--cl29 bdb materialItem--bdb pb8 materialItem--pb8 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-801 sk-text"
                          >
                            今日分享_素材一
                          </div>
                          <div
                            class="pt8 materialItem--pt8 pl8 materialItem--pl8 pr8 materialItem--pr8 data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="cl29 materialItem--cl29 wb materialItem--wb mb8 materialItem--mb8 line-3 materialItem--line-3 data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-155 sk-text"
                            >
                              我进行了编辑
                            </div>
                            <div
                              class="flex materialItem--flex flex-wrap materialItem--flex-wrap flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-video materialItem--media-video data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  object-fit="contain"
                                  src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/ec99d678fd315bdf049d5a8f3a3dbbd6.mp4"
                                  unit-id="true"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                              <div
                                class="media-item materialItem--media-item flex-s0 materialItem--flex-s0 overflow-h materialItem--overflow-h relative materialItem--relative mb8 materialItem--mb8 mr10 materialItem--mr10 data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="media-image materialItem--media-image data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <div
                                  class="media-icon materialItem--media-icon data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                              </div>
                            </div>
                            <div
                              class="flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                            >
                              <span
                                class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                              >
                                近30天内2人发过
                              </span>
                            </div>
                            <div
                              class="media-handle materialItem--media-handle flex materialItem--flex flex-justify-between materialItem--flex-justify-between flex-align-center materialItem--flex-align-center pb4 materialItem--pb4 data-v-08871349 materialItem--data-v-08871349"
                            >
                              <div
                                class="flex-1 materialItem--flex-1 flex materialItem--flex flex-align-center materialItem--flex-align-center data-v-08871349 materialItem--data-v-08871349"
                              >
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    已收藏
                                  </span>
                                </div>
                                <div
                                  class="part materialItem--part data-v-08871349 materialItem--data-v-08871349"
                                ></div>
                                <div
                                  class="handle-box materialItem--handle-box flex materialItem--flex flex-align-center materialItem--flex-align-center fs12 materialItem--fs12 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                                >
                                  <div
                                    class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                  ></div>
                                  <span
                                    class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                  >
                                    编辑
                                  </span>
                                </div>
                              </div>
                              <div
                                class="handle-box materialItem--handle-box flex-s0 materialItem--flex-s0 flex materialItem--flex flex-align-center materialItem--flex-align-center mr8 materialItem--mr8 fs12 materialItem--fs12 cl9c materialItem--cl9c fw500 materialItem--fw500 data-v-08871349 materialItem--data-v-08871349 sk-transparent"
                              >
                                <div
                                  class="icon materialItem--icon mr4 materialItem--mr4 data-v-08871349 materialItem--data-v-08871349 sk-image"
                                ></div>
                                <span
                                  class="fs10 materialItem--fs10 cl9c materialItem--cl9c data-v-08871349 materialItem--data-v-08871349 sk-transparent sk-text-14-2857-929 sk-text"
                                >
                                  发送
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="r materialItem--r data-v-08871349 materialItem--data-v-08871349"
                            style="position: fixed; z-index: 99"
                          ></div>
                          <div
                            class="data-v-08871349 materialItem--data-v-08871349"
                          >
                            <div
                              class="r modalView-index--r data-v-1965c6ec modalView-index--data-v-1965c6ec"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="less" scoped>
.mt9 {
  margin-top: 9px;
}
.material-container {
  width: 100%;
  height: 100%;

  .pbs {
    padding-bottom: calc(8px + env(safe-area-inset-bottom) / 2) !important;
  }
  .material-list {
    min-height: 100%;
    padding-top: 6px;
  }
  .material-item {
    margin-bottom: 8px;
  }
  .material-item:last-child {
    margin-bottom: 0 !important;
  }
  .material-guide {
    line-height: 22px;
    background: linear-gradient(180deg, #fff5f2 0%, #ffffff 100%);
    border: 1px solid #ffffff;
    box-shadow: 0 16px 20px -12px rgba(95, 129, 133, 0.08);

    .hint-icon {
      width: 24px;
      height: 24px;
    }
  }
  .empty-data {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;

    .img {
      width: 200px;
      height: 132px;
    }
  }
  .share-pop-sendItem {
    border-radius: 10px;

    .share-item-icon {
      width: 42px;
      height: 42px;
      margin: 0 auto;
    }

    .cancel-btn {
      line-height: 40px;
    }
  }
}

.pb4 {
  padding-bottom: 4px;
}
.icon {
  width: 16px;
  height: 16px;
}
.line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all; /* 英文换行问题 */
}
.material-item {
  .media-item {
    width: 48px;
    height: 48px;

    .media-image,
    .media-video {
      display: block;
      width: 100%;
      height: 100%;
    }

    .media-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      z-index: 5;
    }
  }
  .media-statu {
    padding: 2px 4px;
    background: rgba(23, 115, 250, 0.08);
  }
  .media-statu-edit {
    color: #ff6f00;
    background: rgba(255, 111, 0, 0.08);
  }
  .media-handle {
    .part {
      width: 32px;
    }
    .handle-box {
      height: 32px;
    }
  }
}

.sk-transparent {
  color: transparent !important;
}
.sk-text-14-2857-801 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text {
  background-origin: content-box !important;
  background-clip: content-box !important;
  background-color: transparent !important;
  color: transparent !important;
  background-repeat: repeat-y !important;
}
.sk-text-14-2857-155 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-929 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 28rpx;
  position: relative !important;
}
.sk-text-14-2857-784 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 28rpx;
  position: relative !important;
}
.sk-text-14-2857-381 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-514 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 28rpx;
  position: relative !important;
}
.sk-text-14-2857-20 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-604 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-202 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 28rpx;
  position: relative !important;
}
.sk-text-14-2857-144 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 28rpx;
  position: relative !important;
}
.sk-text-14-2857-363 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-text-14-2857-479 {
  background-image: linear-gradient(
    transparent 14.2857%,
    #eeeeee 0%,
    #eeeeee 85.7143%,
    transparent 0%
  ) !important;
  background-size: 100% 39.2rpx;
  position: relative !important;
}
.sk-image {
  background: #efefef !important;
}
.sk-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
}
</style>
