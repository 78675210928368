<template>
  <div class="material-item bgf bdr2 fs14">
    <div
      v-if="(searchText || isCollect) && dataSource.group_nav"
      class="fs12 lh17 cl9c pl8 pr8 pt8 wb"
    >
      {{ dataSource.group_nav }}
    </div>
    <div class="pt8 pl8 fw500 cl29 bdb pb8">
      {{ dataSource.title || '素材标题' }}
    </div>
    <div
      v-for="(item, index) in dataSource.children"
      :key="index"
      class="pt8 pl8 pr8"
      @click="onDetail(false, item)"
    >
      <div v-if="item.text_content" class="cl29 wb mb8 line-3 ws">
        {{ item.text_content }}
      </div>
      <div class="flex flex-wrap flex-align-center">
        <div
          v-for="(media, i) in filterTextMedia(item.content_arr)"
          :key="i"
          class="media-item flex-s0 overflow-h relative mb8 mr10"
        >
          <img
            v-if="media.type !== 'VIDEO'"
            class="media-image"
            fit="fill"
            :src="showImgCover(media.file_url, media.type, media.file_suffix)"
          />
          <video
            v-else
            class="media-video"
            preload="metadata"
            object-fit="contain"
            :controls="false"
            :src="`${media.file_url}#t=0.1`"
          />
          <img
            v-if="['VIDEO', 'MINIPROGRAM', 'LINK'].includes(media.type)"
            class="media-icon"
            :src="require(`@/assets/img/quickSend/${media.type}.svg`)"
          />
        </div>
      </div>
      <div class="flex flex-justify-between flex-align-center">
        <text class="fs10 cl9c">{{ item.show || '' }}</text>
        <div
          v-if="item.status_tips && String(item.status_tips) !== '0'"
          class="media-statu fs10 bdr2"
          :class="{
            'media-statu-edit': String(item.status_tips) === '1',
            'txt-blue': String(item.status_tips) !== '1'
          }"
        >
          {{ String(item.status_tips) === '1' ? '我修改过' : '有更新' }}
        </div>
      </div>
      <div
        class="media-handle flex flex-justify-between flex-align-center pb4"
        :class="{ bdb: index !== dataSource.children.length - 1 }"
      >
        <div class="flex-1 flex flex-align-center">
          <div
            class="handle-box flex flex-align-center fs12 cl9c"
            @click.stop="onCollect(item)"
          >
            <img
              class="icon mr5"
              :src="
                require(`@/assets/img/material-library/collect${
                  String(item.is_collect) === '1' ? '' : '-black'
                }.svg`)
              "
            />
            {{ String(item.is_collect) === '1' ? '已' : '' }}收藏
          </div>
          <div class="part" />
          <div
            class="handle-box flex flex-align-center fs12 cl9c"
            @click.stop="onDetail(true, item)"
          >
            <img
              class="icon mr5"
              src="@/assets/img/material-library/edit.svg"
            />
            编辑
          </div>
        </div>
        <div
          class="handle-box flex-s0 flex flex-align-center mr8 fs12 cl9c fw500"
          @click.stop="onSend(item)"
        >
          <img class="icon mr5" src="@/assets/img/material-library/send.svg" />
          发送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setCollectStatu } from '@/service/material.service.js'
import { Toast } from 'vant'
import bus from '@/utils/bus'
import { encodeParams } from '@/utils/router'

export default {
  props: {
    dataSource: {
      // 素材内容
      type: Object,
      default: () => ({})
    },
    searchText: {
      // 搜索框内容
      type: String,
      default: () => ''
    },
    isCollect: {
      // 一级分类是否是收藏
      type: Boolean,
      default: () => false
    },
    map_idx_id: {
      //泛客户id
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    // 过滤文本附件
    filterTextMedia(data = []) {
      return data.filter((item) => item.type !== 'TEXT')
    },
    //封面图片过滤
    showImgCover(url, type, file_suffix, item) {
      // console.log('附件封面', url, type, file_suffix, item)
      if (!url && type == 'LINK') {
        return require(`@/assets/img/quickSend/default_2x.svg`)
      } else if (!url && type !== 'LINK') {
        return ''
      }
      if (type == 'FILE' && ['doc', 'docx'].includes(file_suffix)) {
        return require(`@/assets/img/quickSend/word.svg`)
      } else if (type == 'FILE' && ['pdf'].includes(file_suffix)) {
        return require(`@/assets/img/quickSend/pdf.svg`)
      } else if (type == 'FILE' && ['xlsx', 'xls'].includes(file_suffix)) {
        return require(`@/assets/img/quickSend/excel.svg`)
      } else if (type == 'FILE' && ['pptx', 'ppt'].includes(file_suffix)) {
        return require(`@/assets/img/quickSend/ppt.svg`)
      }
      let baseUrl = url.indexOf('http') === -1 ? 'https://' : ''
      // console.log('附件封面', url)
      return baseUrl + url
    },
    // 点击收藏
    async onCollect({ is_collect, content_key, member_id }) {
      const { searchText, dataSource } = this
      const { id } = dataSource
      const body = {
        type: String(is_collect) === '1' ? 2 : 1,
        content_key,
        sale_script_id: id,
        content_member_id: member_id
      }
      const { code } = await setCollectStatu(body)
      if (code == 200) {
        bus.emit('getMaterialList', { key: 'onShow' })
      }
    },
    // 查看内容包详情
    onDetail(isEdit, { content_key, member_id, status_tips, tips }) {
      const { id, title } = this.dataSource
      const { map_idx_id } = this
      this.$router.push({
        name: 'material-edit',
        query: encodeParams({
          sale_script_id: id,
          content_key,
          member_id,
          status_tips,
          title,
          tips,
          isEdit,
          map_idx_id
        })
      })
    },
    onSend(data = {}) {
      const { content_arr, status_tips } = data
      if (!content_arr || !content_arr.length) return Toast('发送内容不可为空')
      this.$emit('showModal', data)
    }
  }
}
</script>

<style lang="less" scoped>
.pb4 {
  padding-bottom: 4px;
}
.icon {
  width: 16px;
  height: 16px;
}
.line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all; /* 英文换行问题 */
}
.material-item {
  .media-item {
    width: 48px;
    height: 48px;

    .media-image,
    .media-video {
      display: block;
      width: 100%;
      height: 100%;
    }

    .media-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      z-index: 5;
    }
  }
  .media-statu {
    padding: 2px 4px;
    background: rgba(23, 115, 250, 0.08);
  }
  .media-statu-edit {
    color: #ff6f00;
    background: rgba(255, 111, 0, 0.08);
  }
  .media-handle {
    .part {
      width: 32px;
    }
    .handle-box {
      height: 32px;
    }
  }
}
</style>
